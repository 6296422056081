<template>
  <v-col
    cols="12"
    :md="size === 2 ? 6 : size === 3 ? 4 : undefined"
    class="ma-0 pa-0"
  >
    <v-card class="mx-auto"
    >
      <!-- <v-img
        :src="require(`@/assets/MeeTeeMeeNgern/1000x420px/2.1.1000x42 px.jpg`)"
        height="100%">
      </v-img> -->
      <v-card-title>{{value.name}}</v-card-title>
      <v-divider></v-divider>
      <v-card-text v-html="value.description_1">
        <!-- <strong>{{value.responsibilityHeader}}</strong>
        <p>{{value.responsibility1}}</p>
        <p>{{value.responsibility2}}</p>
        <p>{{value.responsibility3}}</p>
        <p>{{value.responsibility4}}</p>
        <p>{{value.responsibility5}}</p>
        <strong>{{value.featureHeader}}</strong>
        <p>{{value.feature1}}</p>
        <p>{{value.feature2}}</p>
        <p>{{value.feature3}}</p>
        <p>{{value.feature4}}</p>
        <p>{{value.responsibility5}}</p> -->
      </v-card-text>
      <!-- <v-card-actions>
        <span class="caption">{{ $t('MeeTeeMeeNgern1.view') }} {{value.view}} คน</span>
        <v-spacer></v-spacer>
        <v-chip
          class="text-uppercase ma-0"
          color="pink"
          dark
          label
          small
          @click.stop=""
        >
          {{ $t('MeeTeeMeeNgern1.readMore') }}
        </v-chip>
      </v-card-actions> -->
    </v-card>
    <!-- </base-card> -->
  </v-col>
</template>

<script>
  export default {
    name: 'FeedCard',

    props: {
      size: {
        type: Number,
        required: true,
      },
      value: {
        type: Object,
        default: () => ({}),
      },
    },
  }
</script>

<style>
.v-image__image {
  transition: .3s linear;
}
</style>
